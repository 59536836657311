import { fetchAndParseWithRetry } from './networkUtils';
import { isError } from './typeUtils';

interface ClearbitDataLayerItem {
  [key: string]: unknown;
  reveal?: {
    company?: string;
    ip?: string;
  };
}

type ClearbitResponse = {
  domain: string;
  geo: { country: string; };

  // Company name
  name: string;
};

type ClearbitUser = {
  country: string;
  domain: string;
  name: string;
};

export const fetchUserData = async ({
  email,
  hostname = '',
}: {
  email: string;

  // Typically, no need to set since this is only used within www
  hostname?: string;
}): Promise<ClearbitUser | null> => {
  try {
    const url = `${hostname}/api/www-vercel-user-enrichment`;

    const data = await fetchAndParseWithRetry<{ user: ClearbitResponse | null; }>(url, {
      body: JSON.stringify({ email }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });

    // console.log('data: ', data); // debug
    const { user } = data || {};

    if (!user) {
      return null;
    }

    return {
      country: user.geo.country,
      domain: user.domain,
      name: user.name,
    };
  } catch (error) {
    if (isError(error)) {
      console.error(`Something went wrong fetching Clearbit company: ${error.toString()}`);
    }
  }

  return null;
};

export const getClearbitData = (): {
  company: string;
  ip: string
} => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return {
      company: '',
      ip: '',
    };
  }

  const dataLayer = window.dataLayer as ClearbitDataLayerItem[];
  const cbReveal = dataLayer.find((item) => item.reveal)?.reveal || {};

  return {
    company: cbReveal.company || '',
    ip: cbReveal.ip || '',
  };
};
